import React, { useRef } from 'react'
import PageConfig from '@/component/layout/PageConfig.tsx'
import { TYPE_TopNav } from '@/component/layout/TopNav/TopNav.tsx'
import HomeBenefit from '@/pages/landing/Home/Benefit/HomeBenefit.tsx'
import SolidButton from '@/component/button/SolidButton.tsx'
import Footer from '@/component/layout/Footer/Footer.tsx'
import HomeMajorFeature from '@/pages/landing/Home/MajorFeature/HomeMajorFeature.tsx'
import HomeIntroduction from '@/pages/landing/Home/HomeIntroduction.tsx'
import { useNavigate } from 'react-router-dom'
import { useLoginStore } from '@/store/LoginStore.ts'
import { useIsVisible } from '@/util/hook/useIsVisible.tsx'
import HomeIntroductionCase from '@/pages/landing/Home/HomeIntorudctionCase.tsx'
import { useTranslation } from 'react-i18next'
import { SALES_URL } from '@/constant/constant.ts'

export interface IProps {
    className?: string
}

const Home: React.FC<IProps> = ({}) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { accessToken } = useLoginStore()


    const onClickGoDashboard = () => {
        if (accessToken) {
            navigate('/dashboard')
        } else {
            navigate('/login')
        }
    }

    const openSalesUrl = () => {
        window.open(SALES_URL, '_blank', 'noopener,noreferrer')
    }


    const seniorContainerRef = useRef<any>()
    const isVisibleSeniorContainer = useIsVisible(seniorContainerRef, true)

    const recommendContainerRef = useRef<any>()
    const isVisibleRecommend = useIsVisible(recommendContainerRef, true)

    return (
        <PageConfig showTopNav={true} typeTopNav={TYPE_TopNav.GUIDE} className={'whitespace-pre-wrap'}>
            <HomeIntroduction openSalesUrl={openSalesUrl} />
            <HomeMajorFeature />
            <HomeBenefit />
            <HomeIntroductionCase />
            {/* 1000만 노인 시대 */}
            <div
                className={`py-[183px] sm:py-[65px] px-[44px] bg-sub_b transition-opacity ease-in duration-[1000ms] ${isVisibleSeniorContainer ? 'opacity-100' : 'opacity-0'}`}
                ref={seniorContainerRef}>
                <div className={'section_1200'}>
                    <p className={'text-[48px] sm:text-[24px] text-main_b font-bold text-center'}>
                        {t('home.summary.title')}
                    </p>
                </div>
            </div>
            {/* 회원관리 시작하기 */}
            <div
                className={`py-[183px] sm:py-[65px] px-[44px] transition-opacity ease-in duration-[1000ms] ${isVisibleRecommend ? 'opacity-100' : 'opacity-0'}`}
                ref={recommendContainerRef}>
                <div className={'section_1200'}>
                    <p className={'text-[48px] sm:text-[24px] font-bold text-center'}>
                        {t('home.start.title')}
                    </p>
                    <div className={'flex sm:flex-col gap-[10px] justify-center mt-[23px]'}>
                        <SolidButton className={'rounded-[10px] min-h-[43px]'} text={t('home.start.service')}
                                     onClick={onClickGoDashboard} />
                        <button className={'bg-white rounded-[10px] px-[20px] py-[10px] min-h-[43px] text-main_b'}
                                onClick={openSalesUrl}>{t('home.start.sales')}</button>
                    </div>
                </div>
            </div>
            <Footer />
        </PageConfig>
    )
}

export default Home