import React, { useRef } from 'react'
import SolidButton from '@/component/button/SolidButton.tsx'
import IntroductionImages from '@/assets/bg_home_introductions_images2.png'
import MobileHeaderImage from '@/assets/img_home_introduction_mobile.jpg'
import { useNavigate } from 'react-router-dom'
import MobileImages from '@/assets/img_home_introduction_images_mobile2.jpg'
import { useLoginStore } from '@/store/LoginStore.ts'
import { useIsVisible } from '@/util/hook/useIsVisible.tsx'
import { useTranslation } from 'react-i18next'

export interface IProps {
    className?: string
    openSalesUrl: () => void
}

const HomeIntroduction: React.FC<IProps> = ({ className, openSalesUrl }) => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { accessToken } = useLoginStore()

    const onClickGoDashboard = () => {
        if (accessToken) {
            navigate('/dashboard')
        } else {
            navigate('/login')
        }
    }
    const imageRef = useRef<any>()
    const isVisible = useIsVisible(imageRef, true)


    return <div
        className={`${className}`}>
        <div
            className={`section_1200 bg-contain bg-no-repeat bg-[url('/img_home_introduction_background.jpg')] sm:hidden`}>
            <h1 className={'pt-[186px] text-center text-[48px] font-bold'}>{t('home.introduction.title')}</h1>
            <p className={'mt-[9px] text-[20px] font-medium text-center'}>{t('home.introduction.desc')}</p>
            <div className={'flex gap-[10px] justify-center mt-[22px]'}>
                <SolidButton className={'rounded-[10px] min-h-[43px]'} text={t('home.introduction.service')}
                             onClick={onClickGoDashboard} />
                <button className={'bg-white rounded-[10px] px-[20px] py-[10px] min-h-[43px] text-main_b'}
                        onClick={openSalesUrl}>{t('home.introduction.sales')}
                </button>
            </div>
            <img src={IntroductionImages}
                 className={`mt-[83px] transition-opacity ease-in duration-[1000ms] ${isVisible ? 'opacity-100' : 'opacity-0'}`}
                 ref={imageRef} />
        </div>
        <div className={'section_1200 hidden sm:flex flex-col items-center'}>
            <img src={MobileHeaderImage} />
            <h1 className={'mt-[24px] text-center text-[24px] font-bold'}>{t('home.introduction.title')}</h1>
            <p className={'mt-[9px] text-[10px] font-medium text-center'}>{t('home.introduction.desc')}</p>
            <div className={'flex flex-col gap-[10px] justify-center mt-[24px]'}>
                <SolidButton className={'rounded-[10px] min-h-[43px]'} text={t('home.introduction.service')}
                             onClick={onClickGoDashboard} />
                <button className={'bg-white rounded-[10px] px-[20px] py-[10px] min-h-[43px] text-main_b'}
                        onClick={openSalesUrl}>{t('home.introduction.sales')}
                </button>
            </div>
            <img src={MobileImages} />
        </div>
    </div>
}

export default HomeIntroduction